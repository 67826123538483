// Check if user ID exists in localStorage
const userId = localStorage.getItem("userId");
if (userId) {
  // If ID exists, redirect to menu page
  window.location.replace("pages/menu.html");
}

const usernameInput = document.getElementById("username");
const startBtn = document.getElementById("startBtn");

usernameInput.addEventListener("input", function() {
  if (usernameInput.value.trim() === "") {
    startBtn.style.opacity = 0.25;
    startBtn.removeAttribute("href");
  } else {
    startBtn.style.opacity = 1;
    startBtn.setAttribute("href", "#");
  }
});

async function saveUser(username) {
  const response = await fetch('/api/users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      username: username
    })
  });
  return await response.json();
}

startBtn.addEventListener("click", async function() {
  if (usernameInput.value.trim() !== "") {
    const user = await saveUser(usernameInput.value.trim());
    localStorage.setItem("userId", JSON.parse(user.id))
    localStorage.setItem("username", usernameInput.value.trim())
    console.log('User saved:', user);
    window.location.replace("pages/menu.html");
  }
});